import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignIn() {
  const navigate = useNavigate();
  const [number, setNumber] = useState();
  const [password, setPassword] = useState([]);
  const [hide, setHide] = useState(false);
  const codeNum = "+91" + number;

  const login = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata?.append("username", codeNum);
    formdata?.append("password", password);
    number?.length === 10 && password?.length > 0
      ? axios
          .post(`${Fetch_Url}/userapi.php?action=login`, formdata, {})
          .then((resp) => {
            // console.log(resp, "login Data");
            resp?.data?.error === false
              ? localStorage.setItem("loginData", number) ||
                (toast.success("LoggedIn Successfull", {
                  position: toast.POSITION.BOTTOM_LEFT,
                }) &&
                  setTimeout(() => {
                    navigate("/");
                  }, 1000))
              : toast.error("Wrong Credentials", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Input Empty Or Mobile No. not Valid", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="slotHeadText">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate("/");
          }}
        ></i>
        <h3>Login</h3>
      </div>
      <div className="signinBox">
        <div className="signinInput">
          <input
            type="number"
            placeholder="Mobile Number"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
        </div>
        <div className="signinInput">
          <input
            type={hide ? "text" : "password"}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <i
            className="fa fa-eye"
            onClick={() => {
              setHide(!hide);
            }}
          ></i>
        </div>
      </div>
      <div className="signinBtn" onClick={login}>
        <button>Login</button>
      </div>
      <div className="signinInfo">
        <Link to="/signup">
          <h4
            onClick={() => {
              navigator.vibrate(100);
            }}
          >
            Register
          </h4>
        </Link>
        <Link to="/forgotpassword">
          <h4
            onClick={() => {
              navigator.vibrate(100);
            }}
          >
            Forgot Password?
          </h4>
        </Link>
      </div>
    </>
  );
}

/* SignIn CSS */
