import {  useNavigate } from "react-router-dom";
import BottomNav from "../Components/BottomNavigate/BottomNav";
import "react-tabs/style/react-tabs.css";
import Carousel from "react-bootstrap/Carousel";
import FloatButton from "../Components/FloatButton/FloatButton"

export default function Home() {
  const navigate = useNavigate();
  // const [notify, setNotify] = useState(false);
  // const [userdetail, setUserdetail] = useState([]);

  // const getUserinfo = () => {
  //   axios
  //     .post(
  //       `${Fetch_Url}/me.php?action=getuserinfo&user=${window.$loginData}`,
  //       {}
  //     )
  //     .then((resp) => {
  //       // console.log(resp, "resp data");
  //       setUserdetail(resp?.data?.user_Data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const [bal, noticeInfo] = userdetail;
  // console.log(bal, noticeInfo, "bal, noticeInfo");

  // useEffect(() => {
  //   getUserinfo();
  // }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("loginData")) {
  //     navigate("/signin");
  //   }
  // }, []);

  const redirect1=()=>{
    if (!localStorage.getItem("loginData")) {
     navigate("/signin");
     }
     else{
      navigate("/win")
     }
  }

  const redirect2=()=>{
    if (!localStorage.getItem("loginData")) {
     navigate("/signin");
     }
     else{
      navigate("/Aviator")
     }
  }

  return (
    <>
      <BottomNav />
      <FloatButton />
      <div className="homeNav">
        {/* <h1>Win1 Club</h1> */}
        <img src="logo win1club.png" alt="" /> 
        <i className="fa fa-bell"></i>
      </div>
      <div className="homeCont">
      <div>
        <Carousel>
          <Carousel.Item>
            <div className="sliderImg">
              <img src="https://img.cooe.in/media/top2.jpg" alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="sliderImg">
              <img src="https://img.cooe.in/media/top2.jpg" alt="" />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="sliderImg">
              <img src="https://img.cooe.in/media/top2.jpg" alt="" />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="allgameBox">
        <div className="gameHead">
          <h3>My Games</h3>
        </div>
        <div className="gameBox">
          <div className="gameCard" onClick={redirect1}>
            <img src="https://cooe.in/images/wingo_enter.png" alt="" />
            <h4>Win Go</h4>
          </div>
         <div className="gameCard" onClick={redirect2}>
          <img src="https://cooe.in/images/aviator_enter.png" alt="" />
          <h4>Aviator</h4>
         </div>
        </div>
        <div className="gameBox">
          <div className="gameCard">
           <img src="https://cooe.in/images/lucky_hit_enter.png" alt="" />
            <h4>Lucky Hit</h4>
          </div>
          <div className="gameCard">
            <img src="https://cooe.in/images/mines_enter.png" alt="" />
            <h4>Mines</h4>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

/* Home CSS */
