import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [number, setNumber] = useState([]);
  const [password, setPassword] = useState([]);
  const [otp, setOtp] = useState([]);

  const getOtp = () => {
    navigator.vibrate(100);
    number?.length === 10
      ? axios
          .post(`${Fetch_Url}/otp.php?num=${number}`, {})
          .then((resp) => {
            // console.log(resp, "getOTP Data");
            toast.success("OTP sent", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Enter Valid Mobile Number", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const resetPass = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata?.append("username", number);
    formdata?.append("password", password);
    formdata?.append("code", otp);
    number?.length === 10 && otp?.length > 0 && password?.length > 0
      ? axios
          .post(`${Fetch_Url}/bet.php?action=reset`, formdata, {})
          .then((resp) => {
            console.log(resp, "register Data");
            if (resp?.data[0]?.status === "Incorrect otp") {
              toast.error("Incorrect OTP", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else {
              toast.success("Reset Password Successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Input can't be Empty", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="slotHeadText">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Reset Password</h3>
      </div>
      <div className="signinBox">
        <div className="signinInput">
          <input
            type="text"
            placeholder="Mobile Number"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
        </div>
        <div className="otpInput">
          <input
            type="text"
            placeholder="Verification Code"
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <h4 onClick={getOtp}>OTP</h4>
        </div>
        <div className="signinInput">
          <input
            type="text"
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="signinBtn" onClick={resetPass}>
        <button>Continue</button>
      </div>
    </>
  );
}

/* SignUp CSS Used in Forgot Password Page */
