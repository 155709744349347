import BottomNav from "../BottomNavigate/BottomNav";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import { Fetch_Url } from "../../Context/Context";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactAudioPlayer from "react-audio-player";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  border: "0px solid white",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const moneydata = [
  {
    id: 1,
    money: 10,
  },
  {
    id: 2,
    money: 100,
  },
  {
    id: 3,
    money: 1000,
  },
  {
    id: 4,
    money: 10000,
  },
];

const numData = [
  {
    id: 1,
    num: 0,
    color: "red",
    shade : "linear-gradient(22deg, rgba(230, 213, 213, 0) 0%, rgba(117, 24, 238, 0.5) 50%, rgba(23, 179, 179, 0) 51%)"
  },
  {
    id: 2,
    num: 1,
    color: "green"
  },
  {
    id: 3,
    num: 2,
    color: "red"
  },
  {
    id: 4,
    num: 3,
    color: "green"
  },
  {
    id: 5,
    num: 4,
    color: "red"
  },
  {
    id: 6,
    num: 5,
    color: "red",
    shade: "linear-gradient(22deg, rgba(129, 38, 233, 0) 0%, rgb(247 67 67 / 88%) 50%, rgb(30 147 45) 51%)"
  },
  {
    id: 7,
    num: 6,
    color: "red"
  },
  {
    id: 8,
    num: 7,
    color: "green"
  },
  {
    id: 9,
    num: 8,
    color: "red"
  },
  {
    id: 10,
    num: 9,
    color: "green"
  },
];

export default function Win() {
  const navigate= useNavigate()
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openNum, setOpenNum] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [userdetailWin, setUserdetailWin] = useState([]);
  const [resdata, setResdata] = useState([]);
  const [betdata, setBetdata] = useState([]);
  const [seconds, setSeconds] = useState(180);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [page, setPage] = useState(1);
  const [pageRes, setPageRes] = useState(1);
  const [randomData, setRandomData] = useState([]);
  const [randomData2, setRandomData2] = useState([]);
  const [intvalue, setIntvalue] = useState(1);
  const [intvalue2, setIntvalue2] = useState(1);
  const [intvalue3, setIntvalue3] = useState(1);
  const [intvalue4, setIntvalue4] = useState(1);
  const [greenbtn, setGreenbtn] = useState({
    id: 1,
    money: 10,
  });
  const [violetbtn, setVioletbtn] = useState({
    id: 1,
    money: 10,
  });
  const [redbtn, setRedbtn] = useState({
    id: 1,
    money: 10,
  });
  const [bluebtn, setBluebtn] = useState({
    id: 1,
    money: 10,
  });

  window.$loginData = localStorage.getItem("loginData");
  // console.log(window.$loginData, "logindata");

  // console.log(intvalue, greenbtn, "intvalue, greenbtn");
  let contractMoney = greenbtn?.money * intvalue;
  // console.log(contractMoney, "contractMoney");

  // console.log(intvalue2, violetbtn, "intvalue2, violetbtn");
  let contractMoney2 = violetbtn?.money * intvalue2;
  // console.log(contractMoney2, "contractMoney2");

  // console.log(intvalue3, redbtn, "intvalue3, redbtn");
  let contractMoney3 = redbtn?.money * intvalue3;
  // console.log(contractMoney3, "contractMoney3");

  // console.log(intvalue4, bluebtn, "intvalue4, bluebtn");
  let contractMoney4 = bluebtn?.money * intvalue4;
  // console.log(contractMoney4, "contractMoney4");

  const handleChange = (Item) => (event, isExpanded) => {
    setExpanded(isExpanded ? Item : false);
  };
  
  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  const userInfo = () => {
    axios
      .post(`${Fetch_Url}/bet.php?action=info&user=${window.$loginData}`, {})
      .then((resp) => {
        console.log(resp, "resp userInfo data");
        setUserdetailWin(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [bal] = userdetailWin;
  // console.log(bal, "bal");

  const periodID = userdetailWin?.find((item) => {
    return item?.period;
  });
  // console.log(periodID, "periodID");

  const resultRecord = React.useCallback(async () => {
    const res = fetch(
      `${Fetch_Url}/bet.php?action=resultrec&page=${pageRes}`
    ).then((res) => res.json());
    const json = await res;
    // console.log(json, "res result data");
    setResdata(json);
  }, [pageRes]);

  useEffect(() => {
    resultRecord();
  }, [resultRecord]);

  const betRecord = React.useCallback(async () => {
    const res = fetch(
      `${Fetch_Url}/bet.php?action=betrec&user=${window?.$loginData}&page1=${page}`
    ).then((res) => res.json());
    const json = await res;
    console.log(json, "res bet data");
    setBetdata(json);
  }, [page]);

  React.useEffect(() => {
    betRecord();
  }, [betRecord]);

  const joinGreen = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney);
    formdata.append("ans", "green");
    if (contractMoney <= bal?.balance) {
      axios
        .post(`${Fetch_Url}/bet.php?action=bet`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinGreen resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpen(false);
            window?.location?.reload(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinViolet = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney2);
    formdata.append("ans", "violet");
    contractMoney2 <= bal?.balance
      ? axios
          .post(`${Fetch_Url}/bet.php?action=bet`, formdata, {})
          .then((resp) => {
            // console.log(resp, "joinViolet resp data");
            resp?.data[0]?.status === "Bet Added Successfully" &&
              toast.success("Bet Added Successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            setOpen2(false);
            window?.location?.reload(false);
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Insufficient Balance", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const joinRed = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney3);
    formdata.append("ans", "red");
    contractMoney3 <= bal?.balance
      ? axios
          .post(`${Fetch_Url}/bet.php?action=bet`, formdata, {})
          .then((resp) => {
            // console.log(resp, "joinRed resp data");
            resp?.data[0]?.status === "Bet Added Successfully" &&
              toast.success("Bet Added Successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            setOpen3(false);
            window?.location?.reload(false);
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Insufficient Balance", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const joinBlue = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney4);
    formdata.append("ans", openNum?.num);
    contractMoney4 <= bal?.balance
      ? axios
          .post(`${Fetch_Url}/bet.php?action=bet`, formdata, {})
          .then((resp) => {
            // console.log(resp, "joinBlue resp data");
            resp?.data[0]?.status === "Bet Added Successfully" &&
              toast.success("Bet Added Successfully", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            setOpenNum(false);
            window?.location?.reload(false);
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Insufficient Balance", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const generateRandomData = () => {
    const newData = [];
    const newData2 = [];
    for (let i = 0; i < 30; i++) {
      // Generate your random data here
      const randomValue = (Math?.floor(100000 + Math?.random() * 900000)); 
      const randomValue2 = (Math?.floor(1000 + Math?.random() * 9000)); 
      newData?.push(randomValue);
      newData2?.push(randomValue2);
    }
    setRandomData(newData);
    setRandomData2(newData2);
  };

  useEffect(() => {
    let interval = null;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  // const formatTime = () => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
  //     .toString()
  //     .padStart(2, "0")}`;
  // };

  useEffect(() => {
    const DateValue = Date.parse(new Date()) / 1e3;
    const h = 180 - (DateValue % 180);
    var i = h / 60,
      n = h % 60;
    setMinute(Math.floor(i));
    setSecond(("0" + Math.floor(n)).slice(-2));
  });
  // console.log(minute, second, "minute, second");

  useEffect(() => {
    if (minute === 0 && second < "30") {
      setOpen(false);
      setOpen2(false);
      setOpen3(false);
      setOpenNum(false);
    }

    if (minute === 3 && second === "00") {
      window?.location?.reload(false);
    }
  }, [minute, second]);

  useEffect(() => {
    userInfo();
    generateRandomData()
  }, []);

  return (
    <>
      <ToastContainer />
      <BottomNav />
      <div className="gameTop">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate("/");
          }}
        ></i>
        <h3>Win Go</h3>
        <h4>
         &#8377; {bal?.balance}
        </h4>
        <i
          className="fa fa-credit-card"
          onClick={() => {
            navigate("/recharge", {state:bal}
            );
          }}
        ></i>
      </div>
      <div className="winCont">
        {/* <div className="winBox">
          <div className="winBal">
            <h3>Available Balance : &#x20b9; {bal?.balance}</h3>
          </div>
          <div className="winBtn">
            <Link to="/recharge" state={bal}>
              <button
                onClick={() => {
                  navigator.vibrate(100);
                }}
              >
                Recharge
              </button>
            </Link>
            {bal?.balance <= 50 && <h4>Balance Low! Recharge Now</h4>}
            <i
              className="fa fa-refresh"
              onClick={() => {
                window?.location?.reload(false);
              }}
            ></i>
          </div>
        </div> */}
        <div className="winParity">
          <h5>Parity</h5>
        </div>
        <div className="winTimebox">
          <div className="winPeriod">
            <h5>
              <i className="fa fa-h4ophy"></i>Period
            </h5>
            <h3>{periodID?.period}</h3>
          </div>
          <div className="winCount">
            <h5>Time Left</h5>
            <h3>
              0{minute} : {second}
            </h3>
          </div>
        </div>
        <div className="winMinicont">
          <div className="joinBtn">
            <div className="joinBtn1"
                 // minute === 0 && second < "30" ? "greyBtn" : "joinBtn1"
            >
            <img src="green.png" alt=""
                   onClick={() => {
                    minute === 0 && second < "30"
                      ? setOpen(false)
                      : setOpen(true);
                  }}
              />
             {/* Join Green */}
            <Modal
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="greenHead">
                    <h2>Join Green</h2>
                  </div>
                  <div className="greenHeadBox">
                    <div className="greenHeadTxt">
                      <h5>Contract Money</h5>
                    </div>
                    <div className="greenHeadBtn">
                      {moneydata?.map((item) => {
                        return (
                          <>
                            <div
                              className={
                                item?.id === greenbtn?.id ? "clickedColor" : ""
                              }
                            >
                              <button
                                onClick={() => {
                                  setGreenbtn(item);
                                }}
                              >
                                {item?.money}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Number</h5>
                    </div>
                    <div className="greenHeadCount">
                      <button
                        disabled={intvalue <= 1}
                        onClick={() => {
                          setIntvalue(intvalue - 1);
                        }}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <h3>{intvalue}</h3>
                      <button
                        onClick={() => {
                          setIntvalue(intvalue + 1);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Total Contract Money is {contractMoney}</h5>
                    </div>
                    <div className="greenHeadTxt2">
                      <h4>
                        <i className="fa fa-check"></i>I agree
                        <span>PRESALE RULE</span>
                      </h4>
                    </div>
                    <div className="signinModalBtn">
                      <button
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        CANCEL
                      </button>
                      <button onClick={joinGreen}>CONFIRM</button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
            <div className="joinBtn2">
                <img src="violet.png" alt=""
                  onClick={() => {
                   minute === 0 && second < "30"
                   ? setOpen2(false)
                   : setOpen2(true);
              }}
              />
              <Modal
                open={open2}
                onClose={() => {
                  setOpen2(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="violetHead">
                    <h2>Join Violet</h2>
                  </div>
                  <div className="greenHeadBox">
                    <div className="greenHeadTxt">
                      <h5>Contract Money</h5>
                    </div>
                    <div className="greenHeadBtn">
                      {moneydata?.map((item) => {
                        return (
                          <>
                            <div
                              className={
                                item?.id === violetbtn?.id ? "clickedColor" : ""
                              }
                            >
                              <button
                                onClick={() => {
                                  setVioletbtn(item);
                                }}
                              >
                                {item?.money}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Number</h5>
                    </div>
                    <div className="greenHeadCount">
                      <button
                        disabled={intvalue2 <= 1}
                        onClick={() => {
                          setIntvalue2(intvalue2 - 1);
                        }}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <h3>{intvalue2}</h3>
                      <button
                        onClick={() => {
                          setIntvalue2(intvalue2 + 1);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Total Contract Money is {contractMoney2}</h5>
                    </div>
                    <div className="greenHeadTxt2">
                      <h4>
                        <i className="fa fa-check"></i>I agree
                        <span>PRESALE RULE</span>
                      </h4>
                    </div>
                    <div className="signinModalBtn">
                      <button
                        onClick={() => {
                          setOpen2(false);
                        }}
                      >
                        CANCEL
                      </button>
                      <button onClick={joinViolet}>CONFIRM</button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
             <div className="joinBtn3">
                  <img src="red.png" alt=""
                     onClick={() => {
                      minute === 0 && second < "30"
                      ? setOpen3(false)
                      : setOpen3(true);
                }}
              />
              <Modal
                open={open3}
                onClose={() => {
                  setOpen3(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="redHead">
                    <h2>Join Red</h2>
                  </div>
                  <div className="greenHeadBox">
                    <div className="greenHeadTxt">
                      <h5>Contract Money</h5>
                    </div>
                    <div className="greenHeadBtn">
                      {moneydata?.map((item) => {
                        return (
                          <>
                            <div
                              className={
                                item?.id === redbtn?.id ? "clickedColor" : ""
                              }
                            >
                              <button
                                onClick={() => {
                                  setRedbtn(item);
                                }}
                              >
                                {item?.money}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Number</h5>
                    </div>
                    <div className="greenHeadCount">
                      <button
                        disabled={intvalue3 <= 1}
                        onClick={() => {
                          setIntvalue3(intvalue3 - 1);
                        }}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <h3>{intvalue3}</h3>
                      <button
                        onClick={() => {
                          setIntvalue3(intvalue3 + 1);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Total Contract Money is {contractMoney3}</h5>
                    </div>
                    <div className="greenHeadTxt2">
                      <h4>
                        <i className="fa fa-check"></i>I agree
                        <span>PRESALE RULE</span>
                      </h4>
                    </div>
                    <div className="signinModalBtn">
                      <button
                        onClick={() => {
                          setOpen3(false);
                        }}
                      >
                        CANCEL
                      </button>
                      <button onClick={joinRed}>CONFIRM</button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
          <div
            className={
              minute === 0 && second < "30" ? "greyNums" : "winNumbers"
            }
          >
            {numData?.map((item) => {
              return (
                <>
                  <h4
                    style={{background: item?.color, backgroundImage: item?.shade}}
                    onClick={() => {
                      minute === 0 && second < "30"
                        ? setOpenNum(false)
                        : setOpenNum(true) || setOpenNum(item);
                    }}
                  >
                    {item?.num}
                  </h4>
                </>
              );
            })}
            <Modal
              open={openNum}
              onClose={() => {
                setOpenNum(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="numHead">
                  <h2>Join {openNum?.num}</h2>
                </div>
                <div className="greenHeadBox">
                  <div className="greenHeadTxt">
                    <h5>Contract Money</h5>
                  </div>
                  <div className="greenHeadBtn">
                    {moneydata?.map((item) => {
                      return (
                        <>
                          <div
                            className={
                              item?.id === bluebtn?.id ? "clickedColor" : ""
                            }
                          >
                            <button
                              onClick={() => {
                                setBluebtn(item);
                              }}
                            >
                              {item?.money}
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="greenHeadTxt">
                    <h5>Number</h5>
                  </div>
                  <div className="greenHeadCount">
                    <button
                      disabled={intvalue4 <= 1}
                      onClick={() => {
                        setIntvalue4(intvalue4 - 1);
                      }}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    <h3>{intvalue4}</h3>
                    <button
                      onClick={() => {
                        setIntvalue4(intvalue4 + 1);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                  <div className="greenHeadTxt">
                    <h5>Total Contract Money is {contractMoney4}</h5>
                  </div>
                  <div className="greenHeadTxt2">
                    <h4>
                      <i className="fa fa-check"></i>I agree
                      <span>PRESALE RULE</span>
                    </h4>
                  </div>
                  <div className="signinModalBtn">
                    <button
                      onClick={() => {
                        setOpenNum(false);
                      }}
                    >
                      CANCEL
                    </button>
                    <button onClick={joinBlue}>CONFIRM</button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <Box
          sx={{
            width: "100%",
            marginTop: "15px"
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange2}
            aria-label="basic tabs example"
            className="gameTabs"
          >
            <Tab label="Parity Records" {...a11yProps(0)} />
            <Tab label="All Records" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
        <div className="resCont">
          <div className="winRecord">
            <i className="fa fa-trophy"></i>
            <h5>Parity Record</h5>
          </div>
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Period</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Numbers</TableCell>
                    <TableCell align="right">Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resdata?.map((item) => (
                    <TableRow key={item?.name}>
                      <TableCell component="th" scope="row">
                        {item?.period}
                      </TableCell>
                      <TableCell align="right" style={{background: "gainsboro", borderRadius: "12px"}}>{item?.num}</TableCell>
                      <TableCell align="right" 
                       style={{background: "gainsboro", borderRadius: "12px"}}
                      >
                        <div>
                          <h4
                            className={
                              item?.ans === "0"
                                ? "redColor"
                                : "greenColor" && item?.ans === "2"
                                ? "redColor"
                                : "greenColor" && item?.ans === "4"
                                ? "redColor"
                                : "greenColor" && item?.ans === "6"
                                ? "redColor"
                                : "greenColor" && item?.ans === "8"
                                ? "redColor"
                                : "greenColor"
                            }
                          >
                            {item?.ans}
                          </h4>
                        </div>
                      </TableCell>
                      <TableCell align="right" 
                       style={{background: "gainsboro", borderRadius: "12px"}}
                      >
                        <div className="tableColor">
                          <h4
                            className={
                              item?.clo === "red" ? "colorRed" : "colorGreen"
                            }
                          ></h4>
                          <h4
                            className={item?.res1 === "violet" && "colorViolet"}
                          ></h4>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="pageBtn">
            <button
              onClick={() => setPageRes(pageRes - 1)}
              disabled={pageRes === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <button onClick={() => setPageRes(pageRes + 1)}>
              <i className="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
         <div className="resCont">
          <div className="winRecord">
            <i className="fa fa-trophy"></i>
            <h5>All Records</h5>
           </div>
           <div className="tableAll">
              <table>
                   <thead>
                    <tr>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>User</th>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>Bet</th>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>Multi.</th>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>Cash Out</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr>
                     <td>
                      {randomData?.map((item)=>{
                       return(
                        <>
                         <div style={{background: "purple", color: "white", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                            <i className="fa fa-user"
                             style={{fontSize: "15px"}}
                           >
                            </i> &nbsp;
                            {item}
                          </div>
                            </>
                          )
                        })}
                       </td>
                       {randomData2?.map((item)=>{
                          return(
                             <>
                             <div style={{background: "gainsboro", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                              &#8377;{item}
                             </div>
                              </>
                            )
                        })}
                        <td>
                        {randomData2?.map(()=>{
                          return(
                             <>
                             <div style={{background: "gainsboro", color: "black", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                               - 
                             </div>
                              </>
                            )
                          })}
                         </td>
                         <td>
                         {randomData2?.map(()=>{
                          return(
                             <>
                             <div style={{background: "gainsboro", color: "black", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                               -
                             </div>
                              </>
                            )
                           })}
                          </td>
                    </tr>
                  </tbody>
                 </table>
                </div>
         </div>
        </TabPanel>
        <div className="betCont">
          <div className="winRecord">
            <i className="fa fa-book"></i>
            <h5>My Record</h5>
          </div>
          {/* <div className="rewardText">
          <h5>No Data Available</h5>
        </div> */}
          <Paper>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                {betdata?.map((item) => {
                  return (
                    <>
                      <Accordion
                        expanded={expanded === item}
                        onChange={handleChange(item)}
                      >
                        <TableHead>
                          <TableRow className="betIcon">
                            <AccordionSummary
                              expandIcon={
                                <div className="expandIcon">
                                  <i className="fa fa-angle-down"></i>
                                </div>
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "98fr 2fr",
                              }}
                            >
                              <Typography
                                sx={{
                                  paddingLeft: "1px",
                                  color: "gray",
                                  flexShrink: 0,
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                {item?.period}
                              </Typography>
                              <Typography
                                className={
                                  (item?.res === "fail" && "redColor") ||
                                  (item?.res === "wait" && "orangeColor") ||
                                  (item?.res === "success" && "greenColor")
                                }
                                sx={{
                                  marginLeft: "15px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                {item?.res}
                              </Typography>
                              <Typography
                                className={
                                  (item?.res === "fail" && "redColor") ||
                                  (item?.res === "wait" && "orangeColor") ||
                                  (item?.res === "success" && "greenColor")
                                }
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  marginLeft: "15px",
                                }}
                              >
                                {item?.ans === "green" &&
                                item?.color2 == "" &&
                                item?.res === "fail"
                                  ? -(item?.amount - (item?.amount * 5) / 100)
                                  : ""}
                                {item?.ans === "green" &&
                                item?.color2 == "" &&
                                item?.res === "success"
                                  ? "+" +
                                    (item?.amount * 2 -
                                      (item?.amount * 2 * 5) / 100)
                                  : ""}

                                {item?.ans === "violet" && item?.res === "fail"
                                  ? -(item?.amount - (item?.amount * 5) / 100)
                                  : ""}
                                {item?.ans === "violet" &&
                                item?.res === "success"
                                  ? "+" +
                                    (item?.amount * 5 -
                                      (item?.amount * 5 * 5) / 100)
                                  : ""}

                                {item?.ans === "red" &&
                                item?.color2 == "" &&
                                item?.res === "fail"
                                  ? -(item?.amount - (item?.amount * 5) / 100)
                                  : ""}
                                {item?.ans === "red" &&
                                item?.color2 == "" &&
                                item?.res === "success"
                                  ? "+" +
                                    (item?.amount * 2 -
                                      (item?.amount * 2 * 5) / 100)
                                  : ""}

                                {!isNaN(item?.ans) && item?.res === "fail"
                                  ? -(item?.amount - (item?.amount * 5) / 100)
                                  : ""}
                                {!isNaN(item?.ans) && item?.res === "success"
                                  ? "+" +
                                    (item?.amount * 8 -
                                      (item?.amount * 8 * 5) / 100)
                                  : ""}

                                {item?.ans == "red" &&
                                item?.color2 == "violet" &&
                                item?.res == "success"
                                  ? "+" +
                                    (item?.amount * 1.5 -
                                      (item?.amount * 1.5 * 5) / 100)
                                  : ""}
                                {item?.ans == "red" &&
                                item?.color2 == "violet" &&
                                item?.res == "fail"
                                  ? -(item?.amount - (item?.amount * 5) / 100)
                                  : ""}

                                {item?.ans == "green" &&
                                item?.color2 == "violet" &&
                                item?.res == "success"
                                  ? "+" +
                                    (item?.amount * 1.5 -
                                      (item?.amount * 1.5 * 5) / 100)
                                  : ""}
                                {item?.ans == "green" &&
                                item?.color2 == "violet" &&
                                item?.res == "fail"
                                  ? -(item?.amount - (item?.amount * 5) / 100)
                                  : ""}
                              </Typography>
                            </AccordionSummary>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <AccordionDetails>
                              <div className="betHead">
                                <h4>Period Detail</h4>
                              </div>
                              <div className="betRecord">
                                <h6>Period</h6>
                                <h6>{item?.period}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Contract Money</h6>
                                <h6>{item?.amount}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Delivery</h6>
                                <h6>
                                  {item?.amount - (item?.amount * 5) / 100}
                                </h6>
                              </div>
                              <div className="betRecord">
                                <h6>Fee</h6>
                                <h6>{(item?.amount * 5) / 100}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Open Price</h6>
                                <h6>{item?.price}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Result</h6>
                                <h6>
                                  {item?.number} {item?.color}
                                </h6>
                              </div>
                              <div className="betRecord">
                                <h6>Select</h6>
                                <h6>{item?.ans}</h6>
                              </div>
                              <div
                                className={
                                  (item?.res === "fail" &&
                                    "redColor betRecord") ||
                                  (item?.res === "wait" &&
                                    "orangeColor betRecord") ||
                                  (item?.res === "success" &&
                                    "greenColor betRecord")
                                }
                              >
                                <h6>Status</h6>
                                <h5>{item?.res}</h5>
                              </div>
                              <div className="betRecord">
                                <h6>Amount</h6>
                                <h6>
                                  {item?.ans === "green" &&
                                  item?.color2 == "" &&
                                  item?.res === "fail"
                                    ? -(item?.amount - (item?.amount * 5) / 100)
                                    : ""}
                                  {item?.ans === "green" &&
                                  item?.color2 == "" &&
                                  item?.res === "success"
                                    ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                    : ""}

                                  {item?.ans === "violet" &&
                                  item?.res === "fail"
                                    ? -(item?.amount - (item?.amount * 5) / 100)
                                    : ""}
                                  {item?.ans === "violet" &&
                                  item?.res === "success"
                                    ? "+" +
                                      (item?.amount * 5 -
                                        (item?.amount * 5 * 5) / 100)
                                    : ""}

                                  {item?.ans === "red" &&
                                  item?.color2 == "" &&
                                  item?.res === "fail"
                                    ? -(item?.amount - (item?.amount * 5) / 100)
                                    : ""}
                                  {item?.ans === "red" &&
                                  item?.color2 == "" &&
                                  item?.res === "success"
                                    ? "+" +
                                      (item?.amount * 2 -
                                        (item?.amount * 2 * 5) / 100)
                                    : ""}

                                  {!isNaN(item?.ans) && item?.res === "fail"
                                    ? -(item?.amount - (item?.amount * 5) / 100)
                                    : ""}
                                  {!isNaN(item?.ans) && item?.res === "success"
                                    ? "+" +
                                      (item?.amount * 8 -
                                        (item?.amount * 8 * 5) / 100)
                                    : ""}

                                  {item?.ans == "red" &&
                                  item?.color2 == "violet" &&
                                  item?.res == "success"
                                    ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                    : ""}
                                  {item?.ans == "red" &&
                                  item?.color2 == "violet" &&
                                  item?.res == "fail"
                                    ? -(item?.amount - (item?.amount * 5) / 100)
                                    : ""}

                                  {item?.ans == "green" &&
                                  item?.color2 == "violet" &&
                                  item?.res == "success"
                                    ? "+" +
                                      (item?.amount * 1.5 -
                                        (item?.amount * 1.5 * 5) / 100)
                                    : ""}
                                  {item?.ans == "green" &&
                                  item?.color2 == "violet" &&
                                  item?.res == "fail"
                                    ? -(item?.amount - (item?.amount * 5) / 100)
                                    : ""}
                                </h6>
                              </div>
                              <div className="betRecord">
                                <h6>Create Time</h6>
                                <h6>{item?.time}</h6>
                              </div>
                            </AccordionDetails>
                          </TableRow>
                        </TableBody>
                      </Accordion>
                    </>
                  );
                })}
              </Table>
            </TableContainer>
          </Paper>
          <div className="pageBtn">
            <button onClick={() => setPage(page - 1)} disabled={page === 1}>
              <i className="fa fa-angle-left"></i>
            </button>
            <button onClick={() => setPage(page + 1)}>
              <i className="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        <div className="audio">
          {minute === 0 && second < 11 && (
            <ReactAudioPlayer
              src="WhatsApp Audio 2023-09-19 at 02.12.53 (1).mpeg"
              autoPlay
              controls
            />
          )}
        </div>
      </div>
    </>
  );
}

// Win CSS
