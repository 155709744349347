export default function FloatButton() {
  return (
    <>
      <div className="telBtn">
        <a href="https://t.me/win1_club" target="_blank">
          <i className="fab fa-telegram"></i>
        </a>
      </div>
    </>
  );
}

/* FloatButton CSS */
