import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Fetch_Url } from "../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// table
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BottomNav from "../../BottomNavigate/BottomNav";
import copy from "copy-to-clipboard";

export default function Promotion() {
  const navigate = useNavigate();
  const [bonusdata, setBonusdata] = useState([]);
  const [userdetail, setUserdetail] = useState([]);
  const [page, setPage] = useState(1);
  const [levelvalue, setLevelvalue] = useState(1);
  const [levelclick, setLevelclick] = useState(true);
  const [leveldata, setLeveldata] = useState([]);

  const bonusInfo = () => {
    axios
      .post(
        `${Fetch_Url}/bet.php?action=invite&usercode=${window?.$loginData}`,
        {}
      )
      .then((resp) => {
        console.log(resp, "bonus resp data");
        setBonusdata(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserinfo = () => {
    axios
      .post(
        `${Fetch_Url}/me.php?action=getuserinfo&user=${window?.$loginData}`,
        {}
      )
      .then((resp) => {
        // console.log(resp, "resp data");
        setUserdetail(resp?.data?.user_Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const levelInfo = () => {
    axios
      .post(
        `${Fetch_Url}/bet.php?action=inviterecord&user=${window?.$loginData}&page=${page}&level=${levelvalue}`,
        {}
      )
      .then((resp) => {
        // console.log(resp, "level resp data");
        setLeveldata(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyText = () => {
    navigator.vibrate(100);
    try {
      copy(`https://kheladda.co.in/#/signup?r_code=${bal?.usercode}`);
      toast.success("Copied Text", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } catch (err) {
      toast.error("Not Copied", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const [bal] = userdetail;
  // console.log(bal, "bal");

  useEffect(() => {
    levelInfo();
  }, [page, levelvalue]);

  useEffect(() => {
    bonusInfo();
    getUserinfo();
  }, []);

  return (
    <>
      <BottomNav />
      <ToastContainer />
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Promotion</h3>
      </div>
      <div className="withCont">
        {bonusdata?.map((item) => {
          return (
            <>
              <div className="balText">
                <h2>Bonus: &#x20b9; {item?.bonus}</h2>
              </div>
              <div className="proFlex">
                <div className="proTxt">
                  <h4>Total People</h4>
                  <h4>{item?.people}</h4>
                </div>
                <div className="proTxt">
                  <h4>Contribution</h4>
                  <h4>&#x20b9; {item?.con}</h4>
                </div>
              </div>
              <div className="proInputbox">
                <div className="proInput">
                  <h4>My Promotion Code</h4>
                  <h5>{bal?.usercode}</h5>
                </div>
                <div className="proInput">
                  <h4>My Promotion Link</h4>
                  <h5>
                    https://kheladda.co.in/#/signup?r_code=
                    {bal?.usercode}
                  </h5>
                </div>
              </div>
              <div className="proBtn" onClick={copyText}>
                <button>Copy Link</button>
              </div>
              <div className="tabsTable">
                <div className="allProtabs">
                  <div
                    className={
                      levelclick === true ? "clickTabs" : "nonclickTabs"
                    }
                    onClick={() => {
                      setLevelvalue(1);
                      setLevelclick(true);
                    }}
                  >
                    <h4>Level 1 ({item?.level1})</h4>
                  </div>
                  <div
                    className={
                      levelclick === false ? "clickTabs" : "nonclickTabs"
                    }
                    onClick={() => {
                      setLevelvalue(2);
                      setLevelclick(false);
                    }}
                  >
                    <h4>Level 2 ({item?.level2})</h4>
                  </div>
                </div>
                <Paper>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                      <TableHead className="proTable">
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell align="right">Phone</TableCell>
                          <TableCell align="right">Water Reward</TableCell>
                          <TableCell align="right">First Reward</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leveldata?.map((item) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {item?.id}
                            </TableCell>
                            <TableCell align="right">
                              {item?.username}
                            </TableCell>
                            <TableCell align="right">0</TableCell>
                            <TableCell align="right">0</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <div className="pageBtn">
                  <button
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    disabled={page === 1}
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                  <button
                    onClick={() => {
                      setPage(page + 1);
                    }}
                  >
                    <i className="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

/* Promotion CSS */
