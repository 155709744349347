import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import { Fetch_Url } from "../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const amount = [
  {
    id: 1,
    bal: "500",
  },
  {
    id: 2,
    bal: "1000",
  },
  {
    id: 3,
    bal: "2000",
  },
  {
    id: 4,
    bal: "5000",
  },
  {
    id: 5,
    bal: "10000",
  },
  {
    id: 6,
    bal: "49999",
  },
];

export default function Recharge() {
  const navigate = useNavigate();
  const location = useLocation();
  const userState = location?.state;
  // console.log(userState, "userState");

  const [balinput, setBalinput] = useState("");
  // console.log(balinput, "balinput");

  // const balData = () => {
  //   const formdata = new FormData();
  //   formdata.append("amount", balinput);
  //   formdata.append("utr", window?.$loginData);
  //   // formdata.append("upi", "321");
  //   balinput > 499
  //     ? axios
  //         .post(
  //           `${Fetch_Url}pay.php?user=${window?.$loginData}&am=${balinput}`,
  //           formdata,
  //           {}
  //         )
  //         .then((resp) => {
  //           console.log(resp, "resp data");
  //           toast.success(`Recharge Successfull`, {
  //             position: toast.POSITION.BOTTOM_LEFT,
  //           });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         })
  //     : toast.error("Input Empty Or Recharge Value at least 500", {
  //         position: toast.POSITION.BOTTOM_LEFT,
  //       });
  // };

  const redirectUrl = () => {
    navigator.vibrate(100);
    balinput > 499
      ? window?.open(
          `https://kheladda.co.in/trova/src/api/pay.php?user=${window?.$loginData}&&am=${balinput}`,
          "_blank"
        )
      : toast.error("Input Empty Or Recharge Value at least 500", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="miniHead">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Recharge</h3>
        <i
          className="fa fa-align-left"
          onClick={() => {
            navigate("/rechargerecord");
          }}
        ></i>
      </div>
      <div className="balCont">
        <div className="balText">
          <h2>Balance: &#x20b9; {userState?.balance}</h2>
          <input
            type="number"
            placeholder="Enter Recharge Amount"
            value={balinput}
            onChange={(e) => {
              setBalinput(e.target.value);
            }}
          />
        </div>
        <div className="balBtns">
          {amount.map((item) => {
            return (
              <>
                <button
                  onClick={() => {
                    setBalinput(item?.bal);
                  }}
                >
                  &#x20b9; {item?.bal}
                </button>
              </>
            );
          })}
        </div>
        {/* <div className="balText2">
          <h5>Payment</h5>
        </div>
        <div className="payText">
          <i className="fa fa-check"></i>
          <h5>EKPay</h5>
        </div> */}
        <div className="signinBtn" onClick={redirectUrl}>
          <button>Recharge</button>
        </div>
      </div>
    </>
  );
}

/* Recharge CSS */
