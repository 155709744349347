import { useEffect, useState } from "react";
import { Howl } from "howler";
import audioFile from "./jet-plane-flybyflac-14641.mp3";
import audioFile2 from "./jet-engine-powering-down-101014.mp3";
import notifySound from "./WhatsApp Audio 2024-06-07 at 18.32.03.mpeg";
import { Fetch_Url } from "../../Context/Context";
import axios from "axios";
// mui
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Aviator() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(false);
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [userdata, setUserdata] = useState([]);
  const [periodinfo, setPeriodinfo] = useState([]);
  const [historydata, setHistorydata] = useState([]);
  const [allresult, setAllresult] = useState([]);
  const [betamount, setBetamount] = useState(10);
  const [pageRes, setPageRes] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedTime, setPausedTime] = useState(0)
  const [randomData, setRandomData] = useState([]);
  const [randomData2, setRandomData2] = useState([]);
  const [value, setValue] = useState(0);
  const [playingSounds, setPlayingSounds] = useState([]);
  const [playingSounds2, setPlayingSounds2] = useState([]);
  const [gameres, setGameres] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
       setIsLoading(false);
    }, 10000);
      
    return () => clearTimeout(timer); // Cleanup function
  }, [isLoading]);

  const startStopwatch = () => {
    playNotificationSound();
    const id = setInterval(() => {
      setTime((prevTime) => prevTime + 10); // Increase by 10 milliseconds
    }, 10);

    setIntervalId(id);
  };

  useEffect(()=>{
    if(isLoading===false){
      startStopwatch();
      getGameResult()
    }
    },[isLoading])

  const stopStopwatch = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const formatTime = (time) => {
    // const milliseconds = `0${time % 1000}`.slice(-7);
    const milliseconds = `0${time % 1500}`;
    const seconds = `${Math.floor((time / 1000) % 60)}`.slice(-2);
    return `${seconds}:${milliseconds.slice(0, 2)}`;
  };

  const handleChange = (Item) => (event, isExpanded) => {
    setExpanded(isExpanded ? Item : false);
  };

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
    
  const togglePause = () => {
    setIsPaused((prevIsPaused) => {
      if (!prevIsPaused) {
        setPausedTime(time/1000); 
      }
      return !prevIsPaused;
    });
  };

  const userInfo = () => {
    axios
      .post(`${Fetch_Url}/bet.php?action=info&user=${window.$loginData}`, {})
      .then((resp) => {
        console.log(resp, "resp userInfo data");
        setUserdata(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [bal] = userdata;
  console.log(bal, "bal");

  const getPeriod = () => {
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_getperiod",
      })
      .then((resp) => {
        console.log(resp, "resp getPeriod data");
        setPeriodinfo(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHistory = () => {
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_result_record",
      })
      .then((resp) => {
        console.log(resp, "resp getHistory data");
        setHistorydata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGameResult = () => {
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_update_result"
      })
      .then((resp) => {
        console.log(resp, "resp getGameResult data");
        setGameres(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllResult = () => {
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_my_bet_record",
        username: window?.$loginData,
        page: pageRes,
      })
      .then((resp) => {
        console.log(resp, "resp getAllResult data");
        setAllresult(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postBet = () => {
    bal?.balance>=betamount
    ?
    betamount>=10 
    ?
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_bet_avaitor",
        username: window?.$loginData,
        period: periodinfo?.periodid,
        amount: betamount,
      })
      .then((resp) => {
        console.log(resp, "resp postBet data");
        if(resp?.data?.success===true){
        toast.success(resp?.data?.result, {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "success"
        })
        userInfo()
        getAllResult()
      }
      else{
         toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_LEFT,
          toastId: "error"
        });
       }
      })
      .catch((err) => {
        console.log(err);
      })
      :
      toast.error("Minimum Amount should be at least 10", {
        position: toast.POSITION.BOTTOM_LEFT,
        toastId: "error"
      })
      :
      toast.error("Please Add Amount", {
        position: toast.POSITION.BOTTOM_LEFT,
        toastId: "error"
      });
  };

  const postCashout = () => {
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_cashout_amount",
        username: window?.$loginData,
        period: periodinfo?.periodid,
        result: pausedTime,
        winning_amount: pausedTime*betamount,
      })
      .then((resp) => {
        console.log(resp, "resp postCashout data");
        if(resp?.data?.success ===true){
        toast.success(`${(pausedTime*betamount)?.toFixed(1)} ${resp?.data?.result}`, {
          position: toast.POSITION.TOP_LEFT,
          toastId: "success"
        })
        const audio= new Audio(notifySound)
        audio?.play()
        userInfo()
        getAllResult()
        }
      else{
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_LEFT,
          toastId: "error"
        });
        }
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const postResult = () => {
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_getresult",
        game_result: gameres?.updated_result,
        period: gameres?.period,
      })
      .then((resp) => {
        console.log(resp, "resp postResult data");
        resp?.data?.success && updatePeriod();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePeriod = () => {
    axios
      .post(`${Fetch_Url}/aviatorapi.php`, {
        aksi: "proses_update_period_id",
      })
      .then((resp) => {
        console.log(resp, "resp updatePeriod data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (time >= gameres?.updated_result * 1000) {
      stopStopwatch();
      setResult(true);
      setTimeout(() => {
        window?.location?.reload(true);
      }, 3000);
    }
  });

  useEffect(()=>{
   result===true && postResult()
  },[result])

  useEffect(() => {
    getPeriod();
    getHistory();
    userInfo()
  }, []);

  useEffect(() => {
    getAllResult();
  }, [pageRes]);

   useEffect(()=>{
    pausedTime > 0 && postCashout()
  },[pausedTime])
 
  const playNotificationSound = () => {
   const sound = new Howl({
      src: [audioFile],
      autoplay: true,
      onend: () => {
        setPlayingSounds(playingSounds.filter(item => item !== sound));
      }
    });
    setPlayingSounds(prev => [...prev, sound]);
  };

  const playAudio = () => {
   const sound = new Howl({
    src: [audioFile2],
    autoplay: true,
    onend: () => {
      setPlayingSounds2(playingSounds.filter(item => item !== sound));
    }
   });
   setPlayingSounds2(prev => [...prev, sound]);
  };

   useEffect(() => {
      if (isLoading === true) {
        playAudio();
      }
    }, [isLoading]);

  const redirectBack = () => {
    playingSounds.forEach(sound => {
      sound.pause();
    });
    playingSounds2.forEach(sound => {
      sound.pause();
    });
    setPlayingSounds([]);
    setPlayingSounds2([]);
    navigate(-1)
  };

   const filteredNumbers = allresult?.find(item => item?.period === periodinfo?.periodid);
   console.log(filteredNumbers?.period, "filteredNumbers") 

   const m=()=>{
      if(filteredNumbers?.period===undefined){
            postBet()
           }
      else if(filteredNumbers?.period!==undefined){
           togglePause()
           }
      }

  const generateRandomData = () => {
    const newData = [];
    const newData2 = [];
    for (let i = 0; i < 30; i++) {
      // Generate your random data here
      const randomValue = (Math?.floor(100000 + Math?.random() * 900000)); // Example: generating random number between 0 and 1
      const randomValue2 = (Math?.floor(1000 + Math?.random() * 9000)); // Example: generating random number between 0 and 1
      newData?.push(randomValue);
      newData2?.push(randomValue2);
    }
    setRandomData(newData);
    setRandomData2(newData2);
  };

  useEffect(()=>{
    generateRandomData()
  },[])

  return (
    <>
    <ToastContainer />
      <div className="gameTop">
        <i
          className="fa fa-arrow-left"
          onClick={redirectBack}
        ></i>
        <h3>Aviator</h3>
        <h4>
         &#8377; {bal?.balance}
        </h4>
        <i
          className="fa fa-credit-card"
          onClick={() => {
            navigate("/recharge", {state:bal}
            );
          }}
        ></i>
      </div>
      <div className="gameCont">
        <div className="slideHistory">
          <Accordion
            expanded={expanded === "item"}
            onChange={handleChange("item")}
          >
            <AccordionSummary
              expandIcon={
                <div className="expandIcon">
                  <i className="fa fa-angle-down"></i>
                </div>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                display: "grid",
                gridTemplateColumns: "98fr 2fr",
              }}
            >
              History
            </AccordionSummary>
            <AccordionDetails>
              <div className="allgameHistory">
                {historydata?.map((item) => {
                  return (
                    <>
                      <div
                        className="gameHistory"
                        style={{ background: item?.color }}
                      >
                        <h4>{item?.result}x</h4>
                      </div>
                    </>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="gamePeriod">
           <h5>
            <i className="fa fa-trophy"></i>Period
           </h5>
            <h3>{periodinfo?.periodid}</h3>
        </div>
        <div className="gameInfo">
          {result ? (
            <div className="loadingUi">
              {/* <img src="bg_plane_dialog_fps_1.png" alt="" /> */}
              <h2>FLEW AWAY</h2>
              <h3>{gameres?.updated_result}x</h3>
            </div>
          ) : isLoading ? (
            <div>
              <div className="loadingUi">
                <img src="loader (2).gif" />
                <h4>WAITING FOR NEXT FLY</h4>
              </div>
            <div className="gameFlex">
              <div className="gameCenter">
                <div className="gameInput">
                <input
                  type="number"
                  placeholder="Amount"
                  // min={10}
                  value={betamount}
                  onChange={(e) => {
                    setBetamount(+e.target.value);
                  }}
                  disabled={filteredNumbers?.period!==undefined}
                />
                </div>
                <div className="gameBtn">
                <button
                  onClick={() => {
                    setBetamount(betamount + 10);
                  }}
                  disabled={filteredNumbers?.period!==undefined}
                >
                  <i className="fa fa-plus"></i>
                </button>
                <button
                  disabled={betamount <= 10 || filteredNumbers?.period!==undefined}
                  onClick={() => {
                    setBetamount(betamount - 10);
                  }}
                >
                  <i className="fa fa-minus"></i>
                </button>
                </div>
              </div>
              <div className="submitBtn">
                <button onClick={m}>
                {(filteredNumbers?.period===undefined && isLoading===true) ? "Bet" : "Wait"}</button>
              </div>
            </div>
          </div>
          ) : (
            <div>
              <div className="loadingUi">
                <img src="Sequence 01_2.gif" />
                <h1>{`${formatTime(time)}x`}</h1>
              </div>
              <div className="gameFlex">
               <div className="gameCenter">
                <div className="gameInput">
                <input
                  type="number"
                  placeholder="Amount"
                  value={betamount}
                  disabled
                />
                </div>
                <div className="gameBtn">
                <button disabled>
                  <i className="fa fa-plus"></i>
                </button>
                <button disabled>
                  <i className="fa fa-minus"></i>
                </button>
                </div>
              </div>
              <div 
                 className={(pausedTime>0 || filteredNumbers?.period===undefined)? "disableBtn":"orangeBtn"}
               >
               <button
                disabled={pausedTime > 0 || filteredNumbers?.period===undefined}
                onClick={m}
                >
                {(filteredNumbers?.period!==undefined || isLoading===false) && "Cash Out"}
                <h3>{((time/1000) * betamount)?.toFixed(1)}</h3>
              </button>
              </div>
             </div>
           </div>
          )}
        </div>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange2}
            aria-label="basic tabs example"
            className="gameTabs"
          >
            <Tab label="My Bets" {...a11yProps(0)} />
            <Tab label="All Bets" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
        <div className="resCont">
          <div className="winRecord">
            <i className="fa fa-trophy"></i>
            <h5>My Record</h5>
          </div>
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Period</TableCell>
                    <TableCell align="right">Bet</TableCell>
                    <TableCell align="right">Multi.</TableCell>
                    <TableCell align="right">Cash out</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allresult?.map((item) => (
                    <TableRow key={item?.id}>
                      <TableCell component="th" scope="row">
                        {item?.period}
                      </TableCell>
                      <TableCell align="right" 
                       style={{background: "gainsboro", borderRadius: "12px"}}>{item?.bet_amount}</TableCell>
                      <TableCell align="right" 
                       style={{background: "gainsboro", borderRadius: "12px"}}
                      >{item?.result}</TableCell>
                      <TableCell align="right" 
                       style={{background: "gainsboro", borderRadius: "12px"}}
                      >
                        {item?.winning_amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="pageBtn">
            <button
              onClick={() => setPageRes(pageRes - 1)}
              disabled={pageRes === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <button onClick={() => setPageRes(pageRes + 1)}>
              <i className="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
         <div className="resCont">
          <div className="winRecord">
            <i className="fa fa-trophy"></i>
            <h5>All Records</h5>
           </div>
           <div className="tableAll">
              <table>
                   <thead>
                    <tr>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>User</th>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>Bet</th>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>Multi.</th>
                    <th style={{background: "orange", color: "white", padding: "14px"}}>Cash Out</th>
                    </tr>
                   </thead>
                   <tbody>
                    <tr>
                     <td>
                      {randomData?.map((item)=>{
                       return(
                        <>
                         <div style={{background: "purple", color: "white", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                            <i className="fa fa-user"
                             style={{fontSize: "15px"}}
                           >
                            </i> &nbsp;
                            {item}
                          </div>
                            </>
                          )
                        })}
                       </td>
                       {randomData2?.map((item)=>{
                          return(
                             <>
                             <div style={{background: "gainsboro", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                              &#8377;{item}
                             </div>
                              </>
                            )
                        })}
                        <td>
                        {randomData2?.map(()=>{
                          return(
                             <>
                             <div style={{background: "gainsboro", color: "black", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                               - 
                             </div>
                              </>
                            )
                          })}
                         </td>
                         <td>
                         {randomData2?.map(()=>{
                          return(
                             <>
                             <div style={{background: "gainsboro", color: "black", borderRadius: "10px", padding: "10px", margin: "1px"}}>
                               -
                             </div>
                              </>
                            )
                           })}
                          </td>
                    </tr>
                  </tbody>
                 </table>
                </div>
         </div>
        </TabPanel>
      </div>
    </>
  );
}

/* Aviator CSS */
